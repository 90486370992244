<template>
	<div>
		<t-header />
		<router-view class="main"></router-view>
		<t-footer />
	</div>
</template>

<script>
	import THeader from "common/header"
	import TFooter from "common/footer"

	export default {
		data() {
			return {
				current: 0,
			};
		},
		components: {
			THeader,
			TFooter
		},
		created() {
		},
		methods: {},
	};
</script>

<style scoped>
	.main {
		min-height: calc(100vh - 250px);
		/* background: #ededed; */
		overflow: hidden;
		width: 100%;
		padding-top: 70px;
		box-sizing: border-box;
	}
</style>
