<template>
	<div class="wrap ccc">
		<div class="main rcc">
			<div class="item cbs" v-if="info">
				<div class="title">联系我们</div>
				<div style="font-size: 16px">{{info.aboutUs.about_us_tel}}</div>
				<div class="address">地址：{{info.aboutUs.about_us_address}}</div>
				<div class="rss">电话:{{info.aboutUs.about_us_phone}}</div>
				<div>QQ：{{info.aboutUs.about_us_qq}}</div>
			</div>
			<div class="line"></div>
			<div class="item df fdc aifs">
				<div class="title df jcfs">网站导航</div>
				<div class="rss fww" v-if="nav.length" style="margin-top: 10px;">
					<router-link :to="item.path" class="nav" v-for="(item, index) in nav" :key="index">
						{{ item.nav }}
					</router-link>
				</div>
				<!-- <div class="title">加盟合作</div>
				<div>
					<router-link class="nav" to="">招商加盟</router-link>
					<router-link class="nav" to="cooperate">大客户合作</router-link>
				</div> -->
			</div>
			<div class="line"></div>
			<div class="item cbs">
				<div class="title">诚信为本 精益求精</div>
				<div class="wechat cbc">
					<img class="qrcode" src="~/assets/common/qrcode.png" alt="">
					<div>官方微信</div>
				</div>
			</div>
		</div>
		<div class="copyright rcc">
			<!-- 版权所有©风擎科技有限公司 保留一切版权 ICP备XXXXXX号 -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "tfooter",
		props: {},
		data() {
			return {
				info: null,
				nav: []
			}
		},
		mounted() {
			this.footerInfo()
		},
		methods: {
			async footerInfo() {
				let {
					info
				} = await this.$api.footerInfo()
				this.info = info
				this.nav = this.info.nav
			}
		},
	};
</script>

<style scoped lang="scss">
	.wrap {
		height: 250px;
		color: #fff;
		background: #434343;
		text-align: center;

		.main {
			width: 1200px;
			height: 220px;
			font-size: .8rem;

			.item {
				width: 33%;
				height: 140px;
				padding-left: 50px;
				// background: blue;
			}
		}

		.copyright {
			width: 100%;
			height: 30px;
			font-size: .7rem;
			background: #222;
		}

		.line {
			width: 1px;
			height: 100px;
			background: #e6e6e6;
		}
	}

	.title {
		font-size: .9rem;
	}

	.nav {
		color: #fff;
		text-decoration: none;
		padding-right: 15px;

		&:last-child {
			padding: 0;
		}
	}

	.wechat {
		height: 90px;
		width: 100px;

		.qrcode {
			width: 70px;
			height: 70px;
		}
	}
</style>
