<template>
	<div class="wrap">
		<!-- <img src="" alt=""> -->
		<div class="main rbc">
			<div @click="$go('/')">LOGO</div>
			<div class="nav-wrap por df jcsb" @mouseleave="hover=false">
				<router-link :to="item.path" v-for="(item, index) in navs" :key="index"
					:class="['nav-item', 'rac']">
					<div @mouseenter="mouseenter" :class="['nav', $route.path == ['/home', '/mall', '/order', '/operator', '/about', '/account'][index] ? 'active' : '']">{{ item.nav }}</div>
				</router-link>
				<div class="poa logout aic df" v-if="hover" @click="logout">登出</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "theader",
		data: () => ({
			hover:false,
			msg: '',
			current: 0,
			navs: [{
				nav: "首页",
				path: '/home'
			}, {
				nav: "我的商城",
				path: '/mall'
			}, {
				nav: "下单系统",
				path: '/order'
			}, {
				nav: "操作流程",
				path: '/operator'
			}, {
				nav: "关于我们",
				path: '/about'
			}, {
				nav: "登录 | 注册",
				path: '/account'
			}],
		}),
		watch:{
			$route(){
				this.isLogin()
			}
		},
		mounted() {
			this.isLogin()
		},
		methods: {
			mouseenter(){
				if(localStorage.getItem('token')) this.hover=true
			},
			logout(){
				localStorage.removeItem('token')
				this.$go('/account')
			},
			isLogin(){
				let token=localStorage.getItem('token')
				if(token){
					this.navs.splice(this.navs.length-1,this.navs.length,{
						nav:'我的',
						path:'/orderSystem/myBalance'
					})
				}else{
					this.navs.splice(this.navs.length-1,this.navs.length,{
						nav: "登录 | 注册",
						path: '/account'
					})
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.wrap {
		width: 100%;
		height: 70px;
		background: #3a3a3a;
		box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
		font-size: 1.9rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #fff;
		line-height: 29px;
		position: fixed;
		z-index: 150;
	}

	div {
		cursor: pointer;
	}

	.main {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
	}

	.nav-wrap {
		width: 800px;
		height: 100%;
		font-size: 1rem;
		.logout{
			right: 0;
			top: 70px;
			background: #3a3a3a;
			color: #fff;
			height: 50px;
			// display: none;
			padding: 0 30px;
		}
	}
	
	.nav-item {
		height: 100%;
		text-decoration: none;
	}

	.nav {
		width: 95px;
		height: 28px;
		color: rgba(238, 238, 238, 0.863);
		text-align: center;
		line-height: 28px;
		border-radius: 5px;

	}

	.nav:hover {
		color: #fff;

	}

	.active {
		color: #fff;
		background: #3f7cf8;
	}
</style>
